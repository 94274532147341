@charset "utf-8";
// *{margin: 0; padding: 0;}
// li{list-style-type: none;}
// a{text-decoration: none; color: inherit;}
// .inner{width: 1200px; margin: 0 auto;}
// body{font: 12px '돋움',dotum,sans-serif; color: #333; text-decoration: none; }
// .test{outline: 1px dotted rgba(255, 0, 0, 0.309);}

/* html{
  background: url(../images/bg1.jpg) no-repeat center /cover fixed;

} */

@media screen and (min-width:681px) {
  

/* #pop{
  z-index: 3; font-size: 11px; line-height: 14px;
  padding: 10px; box-sizing: border-box; color: #fff;
  width: 260px; height: 60px; border-radius: 6px;
  position: absolute; left: 14%; top: 40%;
  background-color: rgba(0, 0, 0, 0.72);
  span{color: rgba(255, 179, 0, 0.99);}
} */

// 레이아웃


.loginall{
  // outline: 1px dotted red;
  max-width: 1200px;
  margin: 0 auto;
  height: 600px;
  // width: 1200px;
  // background-color: royalblue;
  // background-image: url(../img/join_bg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#allwrap{
  // background-color: red;
  // outline: 1px dotted red;
  // margin-top: -100px;
  // position: absolute;
  z-index: 6;
  max-width: 1200px;
  height: 100%;
  // margin: 0 auto;
  // box-shadow: 4px 4px 10px rgba(0,0,0,0.3);
  
  #loginbox{
    position: relative;
    margin: 0 auto;
    // left: 50%; top: 43%; transform: translate(-50%, -50%);
    width: 500px; height: 550px; background-color: rgb(255, 255, 255);
    box-shadow: 4px 4px 15px rgba(0,0,0,0.3);

  .info{
    width: 450px; height: 450px; /* border: 1px solid rgba(0, 0, 0, 0.208); */ margin: 30px 25px; box-sizing: border-box;
    
    form{
      #id{
        width: 360px; height: 45px; margin: 30px 45px 0; background-color: #ffffff00; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.223);
        &:focus{
          outline: none;
          +p.tip{
            display: block;
          }
        }
      
      }  
      p.tip{
        padding:3px 0; width: 360px; display: inline-block; 
        font-size: 10px; text-align: left;
        span{color: red;} margin-left: 45px; 
        display: none;
      }

      #password{
        width: 360px; height: 45px; margin: 5px 45px 0; background-color: #ffffff00; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.211);
        &:focus{
          outline: none;
          &:focus{
            outline: none;
            +p.tip{
              display: block;
            }
          }
        }
      }
      #tel{
        width: 360px; height: 45px; margin: 5px 45px 0; background-color: #ffffff00; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.211);
        &:focus{
          outline: none;
          &:focus{
            outline: none;
            +p.tip{
              display: block;
            }
          }
        }
      }
      #infosave{
        margin: 20px 0 0 45px; 
      
      }
      #infosavelabel{
      
        display: inline-block;
        transform: translateY(-3px);
      }
     /*  .infobtn{display: inline-block; width: 15px; height: 15px; background-color: #fff; margin-left: 5px; transform: translateY(-3px); border-radius: 50%; text-align: center; font-weight: bold; line-height: 15px; cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.435);}
      #loginbtn{
        // width: 360px; height: 45px; margin: 30px 45px 0; background-color: rgba(65, 105, 225, 0.601); 
        border: none; cursor: pointer;
      } */
      p{text-align: center; padding: 20px 25px 0;}
      #qrloginbtn{
        width: 360px; height: 45px; margin: 30px 45px 0; background-color: #f0f0f0; border: none; cursor: pointer;
      }
      
    }
    .btmwrap{
      display: flex; margin: 25px auto; 
      width: 360px; justify-content: space-between;
      button{
        cursor: pointer;
        background-color: #ffffff00; border: none;
      }
      ul{
        line-height: 20px;
        display: flex;
        li:first-child{
          &::after{content: "|"; padding: 0 5px;}
          
        
        }
      }
    }

  }
  }
}



.btmmenu{
  width: 450px; font-size: 9px; margin: 0 auto; 
  ul{
    display: flex; justify-content: space-between;
    line-height: 25px;
    padding:0 55px 0 55px;
    li{
      select{
        background-color: #ffffff00; border: none;
      }
      
    }
  }
  p.copy{ text-align: center; color: rgba(0, 0, 0, 0.344);}

 }

 }

//  -----------임플란트페이지

/* 중간내용끝 */
//  -----------임플란트페이지 끝

// -------교정

// -------교정 끝

 @media screen and (max-width:680px) {
   #banner{display: none;}
   #pop{
     z-index: 3; font-size: 11px; line-height: 14px;
     padding: 10px; box-sizing: border-box; color: #fff;
     width: 260px; height: 60px; border-radius: 6px;
     position: absolute; left: 25px; top: 210px;
     background-color: rgba(0, 0, 0, 0.72);
     span{color: rgba(255, 179, 0, 0.99);}
   }

   #loginbox{
     .logo{
      text-align: center;
    padding: 20px 0; font-size: 20px; font-weight: bold;
    &:focus{outline: none; border: none;}
    }

    

    .info{
      margin: 30px 25px; box-sizing: border-box;

      p{
        // text-align: center;
        // outline: 1px dotted red;
      }
      
      form{
        #id{
          width: 100%; height: 38px; background-color: #ffffff00; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.223);
          &:focus{
            outline: none;
            +p.tip{
              display: block;
            }
          }
        
        }  
        p.tip{
          padding:3px 0; width: 100%; display: inline-block; 
          font-size: 10px; text-align: left;
          span{color: red;} 
          display: none;
        }
  
        #password{
          width: 100%; height: 38px; background-color: #ffffff00; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.211);
          &:focus{
            outline: none;
          }
        }
        #tel{
          width: 100%; height: 38px; background-color: #ffffff00; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.211);
          &:focus{
            outline: none;
          }
        }
        #infosave{
     
          margin: 20px 0 0 0; 
        }
        #infosavelabel{
          display: inline-block;
     
          transform: translateY(-3px);
        }
        .infobtn{display: inline-block; width: 15px; height: 15px; background-color: #fff; margin-left: 5px; transform: translateY(-3px); border-radius: 50%; text-align: center; font-weight: bold; line-height: 15px; cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.435);}
        #loginbtn{
          width: 100%; height: 38px; margin: 30px 0px 0; background-color: rgba(65, 105, 225, 0.601); border: none; cursor: pointer; border-radius: 3px;
        }
        p{text-align: center; padding: 15px 25px 0;}
        #qrloginbtn{
          width: 100%; height: 45px; margin: 15px 0 0; background-color: #f0f0f0; border: none; cursor: pointer;
        }
        
      }
      .btmwrap{
        // outline: 1px dotted red;
        // display: flex; margin: 25px auto; 
        // width: 100%; justify-content: space-between;
        button{
          cursor: pointer;
          background-color: #ffffff00; border: none;
        }
        ul{
          // line-height: 20px;
          display: flex;
          li:first-child{
            &::after{content: "|"; padding: 0 5px;}
            
          
          }
        }
      }
  
    }


  }

  .btmmenu{
    // position: absolute; bottom: 0;
    
    width: 100%; font-size: 9px; margin: 0 auto; 

    ul{
      width: 350px;
      display: flex; justify-content: space-between;
      line-height: 25px;
      margin: 0 auto; margin-bottom: 10px;
      li{
        select{
          background-color: #ffffff00; border: none;
        }
        
      }
    }
    p.copy{ text-align: center; color: rgba(0, 0, 0, 0.344); padding-bottom: 15px;}

  }
  


}






