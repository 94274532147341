* {
  box-sizing: border-box;
}
body {
  margin: 0;
  overflow-x: hidden; /* 가로 스크롤 방지 */
  /* background-color: rgba(0,0,0,0.05); */
  background-image: url(./img/bodybg.webp);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}