
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;

  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;

  
}